<template>
    <div class="intercoms-list">
        <h1>{{ $t('intercomsList.title', {count: intercoms.length}) }}</h1>
        <table class="table intercoms-table">
            <thead>
                <tr>
                    <th>{{ $t('intercomsList.arrayColumns.name') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in intercoms" :key="item.id">
                    <td>{{ item }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import v1mixin from '@/mixins/v1.js'

export default {
    name: 'IntercomsList',
    mixins: [
        v1mixin
    ],
    props: {
        intercoms: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.intercoms-list {
    padding: 30px 0 30px 0;
    border-radius: 30px;
    box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 15%);

    h1 {
        font-size: $normal;
        font-family: 'Avenir Heavy';
        text-align: center;
        margin-bottom: 35px;
    }

    .intercoms-table {
        border-color: $grey-neutral;
        color: $blue-dark;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        thead {
            border: none;

            tr {
                border-color: $grey-neutral;

                th {
                    font-size: $normal;
                }

            }
        }

        tbody {
                border-color: $grey-neutral;

            tr {
                border-color: $grey-neutral;
                transition: background-color 100ms, color 100ms;

                td {
                    vertical-align: middle;
                    padding: 10px;
                    font-size: $normal;
                }

                td:last-child {
                }
            }

            tr:hover {
                background-color: $grey-light;
                cursor: pointer;
                color: $orange-neutral;

                td {
                }
            }
        }
    }
}

</style>
